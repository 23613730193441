

































































































import { Component, Mixins, Prop } from 'vue-property-decorator'

import Card from '@/components/cards/Card.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import SystemMixin from '@/mixins/SystemMixin'
import { InsuranceResource } from '@/store/types'
import { openNewTab } from '@/utils/functions'
import { DateTime } from 'luxon'

@Component({
  components: {
    Card,
  },
})


export default class PolicyCard extends Mixins(NotifyMixin, SystemMixin){
  @Prop({ required: true })
  private card!: InsuranceResource

  @Prop({ default: () => ([]) })
  private checkedList!: number[]

  @Prop({ default: true })
  private isVisibleVin!: boolean

  private get innerItem () {
    let innerItem = {}
    if (this.card.osago && this.card.casco){
      innerItem = {
        ...this.card,
        osago: {
          ...this.card.osago,
          startDate: DateTime.fromFormat(`${this.card.osago.startDate}`, 'yyyy-MM-dd').toFormat('dd.MM.yy'),
          endDate: DateTime.fromFormat(`${this.card.osago.endDate}`, 'yyyy-MM-dd').toFormat('dd.MM.yy'),
        },
        casco: {
          ...this.card.casco,
          startDate: DateTime.fromFormat(`${this.card.casco.startDate}`, 'yyyy-MM-dd').toFormat('dd.MM.yy'),
          endDate: DateTime.fromFormat(`${this.card.casco.endDate}`, 'yyyy-MM-dd').toFormat('dd.MM.yy'),
        },
      }
    } else if (!this.card.casco){
      innerItem = {
        ...this.card,
        osago: {
          ...this.card.osago,
          startDate: DateTime.fromFormat(`${this.card.osago.startDate}`, 'yyyy-MM-dd').toFormat('dd.MM.yy'),
          endDate: DateTime.fromFormat(`${this.card.osago.endDate}`, 'yyyy-MM-dd').toFormat('dd.MM.yy'),
        },
      }
    } else {
      innerItem = {
        ...this.card,
        casco: {
          ...this.card.casco,
          startDate: DateTime.fromFormat(`${this.card.casco.startDate}`, 'yyyy-MM-dd').toFormat('dd.MM.yy'),
          endDate: DateTime.fromFormat(`${this.card.casco.endDate}`, 'yyyy-MM-dd').toFormat('dd.MM.yy'),
        },
      }
    }
    return innerItem

  }

  private downloadPDF (cardId: number) {
    let dataObj = {
      insurance_id: cardId,
    }
    openNewTab(`/insurance-pdf/${+this.entity}`, dataObj, false)
  }
}
