






























































// CORE
import { Component, Mixins, Watch } from 'vue-property-decorator'

// INTERFACES
import { CountResource } from '@/store/types'

// COMPONENTS
import FiltersPanel from '@/components/FiltersPanel.vue'
import PolicyCard from '@/components/cards/PolicyCard.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import TableFooter from '@/components/TableFooter.vue'
import CaptionCard from '@/components/cards/CaptionCard.vue'

//STORE
import PoliciesModule from '@/store/modules/policies'
import SystemMixin from '@/mixins/SystemMixin'
// import { openNewTab } from '@/utils/functions'

@Component({
  components: {
    FiltersPanel,
    PolicyCard,
    TableFooter,
    CaptionCard,
  },
})

export default class PoliciesTab extends Mixins(NotifyMixin, SystemMixin) {
  /**
   * LOCAL DATA
   */
  private isVisibleVin = false
  private checkedList: number[] = []
  private checkedListIds: number[] = []
  /**
   * COMPUTED PROPERTIES
   */

  private get policiesFilter () {
    return PoliciesModule.policiesFilter
  }

  private set policiesFilter (val: any){
    PoliciesModule.setPoliciesFilter(val)
  }

  private get statuses (): CountResource[] {
    return PoliciesModule.statuses
  }

  private get activeStatus (): string | undefined {
    return PoliciesModule.policiesFilter.status
  }

  private set activeStatus (value: string | undefined) {
    PoliciesModule.setActiveStatus(value)
    PoliciesModule.getPolicies(+this.entity)
      .catch(this.notifyError)
  }

  private get indeterminate (): boolean {
    return this.checkedList.length !== this.policies.length && !!this.checkedList.length
  }

  private get policies () {
    return PoliciesModule.policies.data
  }

  private get pagination () {
    return PoliciesModule.policies.meta
  }

  /**
   * METHODS
   */

  private changeVin () {
    this.isVisibleVin = !this.isVisibleVin
  }

  private fetchPolicies () {
    PoliciesModule.getPolicies(+this.entity)
      .then(() => {
        this.checkedList = []
        this.checkedListIds = []
      })
      .catch(this.notifyError)
  }

  private handleToggleAll () {
    if (this.checkedList.length !== this.policies.length) {
      this.checkedList = this.policies.map(item => item.id)
    } else {
      this.checkedList = []
    }
  }

  private downloadMultiplePolicies () {
    this.checkedList.forEach(el => {
      this.policies
        .filter(item => item.id === el)
        .forEach(item => {
          if (item.casco) {
            this.checkedListIds.push(item.casco.id)
          }
          if (item.osago) {
            this.checkedListIds.push(item.osago.id)
          }
        })
    })
    PoliciesModule.getPoliciesPDFLink({ carParkId: +this.entity, params: { insuranceIds: this.checkedListIds } })
      .then(data => {
        if (!data.errors && data.link) {
          const tab = window.open(data.link, '_blank')

          if (tab) {
            tab.focus()
          }
        } else if (data.errors && this.checkedList.length > data.errors.length) {
          this.$openModal(
            'PDFDownloadModal',
            'small-no-absolute',
            {
              exportURL: data.link,
              checkedItems: this.checkedList,
              errorItems: data.errors,
            },
          )
        } else {
          this.notifyError('Выбранные файлы не могут быть выгружены в общий pdf. Попробуйте по отдельности.')
        }
      })
      .finally(() => {
        this.checkedListIds = []
      })

    // let dataObj = {
    //   insurance_ids: this.checkedList,
    // }
    // openNewTab('/insurance-zip/', dataObj, false)
  }

  /**
   * LIFECYCLE HOOKS
   */

  private created () {
    PoliciesModule.getStatuses(+this.entity)
    PoliciesModule.getDictionaries(+this.entity)
    PoliciesModule.getPolicies(+this.entity)
      .catch(this.notifyError)
  }

  /**
   * WATCHERS
   */

  @Watch('policiesFilter')
  private watchPoliciesFilter () {
    this.fetchPolicies()
  }
  @Watch('checkedList')
  private watchcheckedList () {
    if (this.checkedList.length === 0) {
      this.checkedListIds = []
    }
  }
}
